import { t } from '@/i18n/translation_util';

import { defaultTrialDays } from '@/lib/promo-codes';
import { useCheckoutStore } from '../check-out.hooks';

export function PricingLabel2() {
  const paymentPlans = useCheckoutStore((s) => s.planOptions);

  return (
    <div className="text-center text-[18px] font-[500] leading-[28px] text-[#667085] [@media(max-width:767px)]:text-[16px] [@media(max-width:767px)]:font-[400] [@media(max-width:767px)]:leading-[21px]">
      <div
        dangerouslySetInnerHTML={{
          __html:
            'monthly' in paymentPlans
              ? t(
                  'Get unlimited listening for trialDays its priceMonthly or priceYearly',
                  {
                    trialDays: defaultTrialDays,
                    priceMonthly: paymentPlans.monthly.amount,
                    priceYearly: paymentPlans.yearly.amount,
                  },
                )
              : t('Get unlimited listening for trialDays its priceYearly', {
                  trialDays: defaultTrialDays,
                  priceYearly: paymentPlans.yearly.amount,
                }),
        }}
      />
    </div>
  );
}
